<template>
  <section class="gamelist">
    <div class="listBg">
      <h2>CASINO</h2>
      <ul class="listWrap">
        <template v-for="item in commonCodeByOrder['casino']" v-bind:key="item.vendorKey">
          <li v-if="item.groupCodeName.indexOf('HC-') == -1" @click="onCasinoSelectGame(item.groupCode, item.code)">
            <div :style="getBackgroundImage(item)" class="listImg">
              <em class="glogo" :style="getBackgroundLogoImage(item)"></em>
            </div>
            <div class="gameEnter"></div>
            <!-- <p class="name">
              <span>{{$i18n.locale !== 'ko' ? item[`codeName${$i18n.locale.toUpperCase()}`] : item.codeName}}</span>
              <span class="gameNameEN">{{item.codeNameEN}}</span>
            </p> -->
          </li>
        </template>
      </ul>
    </div>
  </section>
</template>

<script>
import '@/styles/common.css'
import '@/styles/main.css'
import { mapState } from 'vuex'

export default {
  name: 'gameCasino',
  components: {
  },
  data () {
    return {
    }
  },
  computed: {
    ...mapState([
      'userData',
      'commonCode',
      'commonCodeByOrder',
      'gameCount'
    ])
  },
  methods: {
    getBackgroundImage (item) {
      try {
        const image = require('../../../assets/img/game_main_pc_' + item.code + '_off.png')
        const imageOff = require('../../../assets/img/game_main_pc_' + item.code + '_off.png')
        if (image && imageOff) {
          if (item.isHover) {
            return { backgroundImage: 'url(' + image + ')' }
          } else {
            return { backgroundImage: 'url(' + imageOff + ')' }
          }
        } else {
          return { backgroundImage: 'url()' }
        }
      } catch (e) {
        return { backgroundImage: 'url()' }
      }
    },
    getBackgroundLogoImage (item) {
      try {
        const image = require('../../../assets/img/glogo' + item.code + '.png')
        return { backgroundImage: 'url(' + image + ')' }
      } catch (e) {
        return { backgroundImage: 'url()' }
      }
    }
  }
}
</script>

<style scoped src="@/styles/gamelist.css"></style>
<style></style>
